import { Footer, Question, SelectLang, AvatarDropdown, AvatarName } from '@/components';
import { LinkOutlined } from '@ant-design/icons';
import { message, Tabs } from 'antd';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import { SettingDrawer } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history, Link } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
// import { currentUser as queryCurrentUser } from '@/services/ant-design-pro/api';

import { dataFetcher, queryMe } from '@/services/api';
import React, { Fragment, useEffect } from 'react';
import { Icon } from '@iconify-icon/react';
import routes from '../config/routes';

const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/user/login';
const firstPath = '/welcome';

const { TabPane } = Tabs;

export const getCustomTabs = () => {
  return ({ isKeep, keepElements, navigate, dropByCacheKey, local, activeKey }: any) => {
    console.log('keepElements.current', keepElements.current);
    console.log('local', local);
    console.log('activeKey', activeKey);
    console.log('navigate', navigate);

    const listenMessage = (event: any) => {
      const { targetKey, aim, action } = event.data;
      //
      if (action === 'closeTab') {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        const newPanel = Object.keys(keepElements.current);

        newActiveKey = aim;
        dropByCacheKey(targetKey);
        if (newActiveKey !== location.pathname) {
          navigate(newActiveKey);
        }
      }
    };

    useEffect(() => {
      console.log('balabala');

      // history.push(activeKey)
      // history.push(firstPath)
      // history.push(keepElements.current[activeKey])

      // history.push()
    }, []);

    useEffect(() => {
      window.addEventListener('message', listenMessage, false);
    }, []);
    return (
      <div className="rumtime-keep-alive-tabs-layout" hidden={!isKeep}>
        <Tabs
          hideAdd
          onChange={(key: any) => {
            navigate(key);
          }}
          destroyInactiveTabPane
          activeKey={activeKey}
          type="editable-card"
          onEdit={(targetKey: any) => {
            let newActiveKey = activeKey;
            let lastIndex = -1;
            const newPanel = Object.keys(keepElements.current);

            for (let i = 1; i < newPanel.length; i++) {
              if (newPanel[i] === targetKey) {
                lastIndex = i - 1;
              }
            }
            const newPanes = newPanel.filter((pane) => pane !== targetKey);

            if (newPanes.length && newActiveKey === targetKey) {
              if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex];
              } else {
                newActiveKey = newPanes[0];
              }
            }
            if (lastIndex === -1 && targetKey === location.pathname) {
              message.info('至少要保留一个窗口');
            } else {
              dropByCacheKey(targetKey);
              if (newActiveKey !== location.pathname) {
                navigate(newActiveKey);
              }
            }
          }}
        >
          {Object.entries(keepElements.current).map(([pathname, element]: any) => (
            <TabPane
              tab={`${local[pathname] || pathname}`}
              key={pathname}
              closable={pathname !== firstPath}
              destroyInactiveTabPane
            />
          ))}
        </Tabs>
      </div>
    );
  };
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      const { manager } = await dataFetcher(queryMe);

      console.log('manager', manager);

      return manager;
    } catch (error) {
      console.log('error===', error);

      history.push(loginPath);
    }
    return undefined;
  };
  // 如果不是登录页面，执行
  const { location } = history;
  if (location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();

    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }
  return {
    fetchUserInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  useEffect(() => {
    // const handleBeforeUnload = (event: any) => {
    //   event.preventDefault();
    //   // Custom logic to handle the refresh
    //   // Display a confirmation message or perform necessary actions
    // };
    // window.addEventListener('beforeunload', handleBeforeUnload);
    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);
  console.log('initialState', initialState);

  return {
    actionsRender: () => [<Question key="doc" />, <SelectLang key="SelectLang" />],
    avatarProps: {
      src: initialState?.currentUser?.avatar,
      title: <AvatarName />,
      render: (_, avatarChildren) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    waterMarkProps: {
      content: ['技术体验版', '仅供技术测试'],
      fontColor: 'rgba(0,0,0,.1)',
    },
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },
    bgLayoutImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    links: isDev
      ? [
          <Link key="pymio" to="https://0ldm0s.net/" target="_blank">
            <LinkOutlined />
            <span>Powered By Pymio</span>
          </Link>,
        ]
      : [],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <>
          {children}
          {isDev && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )}
        </>
      );
    },

    menuDataRender: (menuData) => {
      return menuData.map((item) => {
        return {
          ...item,
          icon:
            typeof item.icon === 'string' && item.icon.includes(':') ? (
              // <span className={`${item.icon}`} style={{width:24, height:24}}></span>
              <Icon icon={item.icon} />
            ) : (
              item.icon
            ),
        };
      });
    },

    ...initialState?.settings,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};
