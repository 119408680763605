/**
 * @see https://umijs.org/docs/max/access#access
 * */
export default function access(initialState: { currentUser?: API.CurrentUser } | undefined) {
  const { currentUser } = initialState ?? {};

  const canAdmin = currentUser && currentUser.roleId === 1;

  return {
    canAdmin,
    normalRouteFilter: (route: any) => {
      // console.log('route1111111111', route);
      if (canAdmin) return true;
      if (route.path === '/welcome') return true;
      const findedRoute = currentUser?.rolePermissions.find((item) => item.key === route.path);

      if (findedRoute) {
        return findedRoute.canRead;
      }
    }, // initialState 中包含了的路由才有权限访问
  };
}
