
import { DefaultFooter } from '@ant-design/pro-components';
import React from 'react';


const Footer: React.FC = () => {
  return (
    // <DefaultFooter
    //   style={{
    //     background: 'none',
    //   }}
    //
    //   links={[
    //     // {
    //     //   key: 'React ',
    //     //   title: 'React &',
    //     //   href: 'javascript:void(0)',
    //     //
    //     // },
    //     //
    //     // {
    //     //   key: 'pymio',
    //     //   title: 'pymio',
    //     //   href: 'javascript:void(0)',
    //     //
    //     // },
    //   ]}
    //
    //   // copyright='2024 版权所有 '
    //
    // />
    <></>
  );
};

export default Footer;
