import request from './index';
import { notification } from 'antd';

export const baseURL = 'https://momo.0ldm0s.top';
export const sanboxURL = 'https://fudosan.0ldm0s.net';
// export const apiURL = process.env.NODE_ENV === 'development' ? '/api/b/v1.0' : '/api/b/v1.0';
export const domain = process.env.NODE_ENV === 'development' ? sanboxURL : baseURL;
export let isTauri = false; // ! 需要手动修改
if (process.env.NODE_ENV !== 'production') {
  isTauri = false; // ! 强制设为否
}
const BASE_SERVER = isTauri ? domain : '';
const GQL_API = BASE_SERVER + '/api/admin/query.json';

export const gqlRequest = async (params?: any) => request.post(GQL_API, JSON.stringify(params));

export const getImagePath = (image: string) => {
  if (image.startsWith('http')) return image;
  if (process.env.NODE_ENV === 'development') return `${sanboxURL}${image}`;
  return `${baseURL}${image}`;
};

/**
 * 查询类操作
 */
// 查自己
export const queryMe = `
query {
  manager {
    oid
    username
    roleId
    roleName
    rolePermissions {
      key
      canRead
      canWrite
      canDelete
    }
  }
}
`;

// 获取管理员角色列表
export const queryPlatformRoleList = `
query PlatformRole(
  $page: Int,
  $offset: Int,
  $showPermission: Boolean,
) {
  adminGetPlatformRoleList(
    page: $page,
    offset: $offset,
    showPermission: $showPermission,
  ){
    totalCount
    itemList {
      oid
      name
      userCount
    }
  }
}
`;

// 获取管理员角色详情
export const queryPlatformRole = `
query PlatformRole(
  $oid: Int!
) {
  adminGetPlatformRole(
    oid: $oid
  ){
    oid
    name
    permissions {
      key
      canRead
      canWrite
      canDelete
    }
    userCount
  }
}
`;

// 获取管理员列表
export const queryPlatformUserList = `
query PlatformUser(
  $page: Int,
  $offset: Int,
  $showPermission: Boolean,
) {
  adminGetPlatformUserList(
    page: $page,
    offset: $offset,
    showPermission: $showPermission,
  ){
    totalCount
    itemList {
      oid
      username
      roleId
      roleName
    }
  }
}
`;

// 获取管理员信息
export const queryPlatformUser = `
query PlatformUser(
  $oid: Int!,
) {
  adminGetPlatformUser(
    oid: $oid,
  ){
    oid
    username
    roleId
    roleName
  }
}
`;

// 银行列表
export const queryBankList = `
query Bank(
  $page: Int,
  $offset: Int,
  $keyword: String,
) {
  adminGetBankList(
    page: $page,
    offset: $offset,
    keyword: $keyword,
  ){
    totalCount
    itemList {
      oid
      swiftcode
      bankName
      bankBranch
      createAt
      updateAt
    }
  }
}
`;

// 银行详情
export const queryBank = `
query Bank(
  $oid: String!,
) {
  adminGetBank(
    oid: $oid,
  ){
    oid
    swiftcode
    bankName
    bankBranch
    createAt
    updateAt
  }
}
`;

// 银行账号列表
export const queryBankAccountList = `
query BankAccount(
  $page: Int,
  $offset: Int,
  $keyword: String,
  $bankId: String,
  $bankAccount: String,
) {
  adminGetBankAccountList(
    page: $page,
    offset: $offset,
    keyword: $keyword,
    bankId: $bankId,
    bankAccount: $bankAccount
  ){
    totalCount
    itemList {
      oid
      bankAccount
      bankAccountType
      bankAccountTypeNo
      bankBranchNumber
      bankId
      swiftcode
      bankName
      bankBranch
      createAt
      updateAt
    }
  }
}
`;

// 银行账号详情
export const queryBankAccount = `
query BankAccount(
  $oid: String!,
) {
  adminGetBankAccount(
    oid: $oid,
  ){
    oid
    bankAccount
    bankAccountType
    bankAccountTypeNo
    bankBranchNumber
    bankId
    swiftcode
    bankName
    bankBranch
    createAt
    updateAt
  }
}
`;

// 房东列表
export const queryOwnerList = `
query Owner(
  $page: Int,
  $offset: Int,
  $name: String,
  $mobile: String,
  $email: String,
  $wechat: String,
  $bankAccount: String,
  $bankId: String,
  $balanceMin: Int,
  $balanceMax: Int,
) {
  adminGetOwnerList(
    page: $page,
    offset: $offset,
    name: $name,
    mobile: $mobile,
    email: $email,
    wechat: $wechat,
    bankAccount: $bankAccount,
    bankId: $bankId,
    balanceMin: $balanceMin,
    balanceMax: $balanceMax,
  ){
    totalCount
    itemList {
      oid
      firstName
      firstNameEn
      lastName
      lastNameEn
      passportId
      mobile
      email
      wechat
      balance
      bankAccount
      bankAccountType
      bankAccountTypeNo
      bankBranchNumber
      bankId
      swiftcode
      bankName
      bankBranch
      createAt
      updateAt
    }
  }
}
`;

// 房东详情
export const queryOwner = `
query Owner(
  $oid: String!,
) {
  adminGetOwner(
    oid: $oid,
  ){
    oid
    firstName
    firstNameEn
    lastName
    lastNameEn
    passportId
    mobile
    email
    wechat
    balance
    bankAccount
    bankAccountType
    bankAccountTypeNo
    bankBranchNumber
    bankId
    swiftcode
    bankName
    bankBranch
    createAt
    updateAt
  }
}
`;

// 入住人列表
export const queryTenantList = `
query Tenant(
  $page: Int,
  $offset: Int,
  $name: String,
  $mobile: String,
  $email: String,
  $passportId: String,
  $bankAccount: String,
) {
  adminGetTenantList(
    page: $page,
    offset: $offset,
    name: $name,
    mobile: $mobile,
    email: $email,
    passportId: $passportId,
    bankAccount: $bankAccount,
  ){
    totalCount
    itemList {
      oid
      firstName
      firstNameEn
      lastName
      lastNameEn
      passportId
      mobile
      email
      wechat
      balance
      bankAccount
      bankAccountType
      bankAccountTypeNo
      bankBranchNumber
      bankId
      swiftcode
      bankName
      bankBranch
      createAt
      updateAt
    }
  }
}
`;

// 入住人详情
export const queryTenant = `
query Tenant(
  $oid: String!,
) {
  adminGetTenant(
    oid: $oid,
  ){
    oid
    firstName
    firstNameEn
    lastName
    lastNameEn
    passportId
    mobile
    email
    wechat
    balance
    bankAccount
    bankAccountType
    bankAccountTypeNo
    bankBranchNumber
    bankId
    swiftcode
    bankName
    bankBranch
    createAt
    updateAt
  }
}
`;

// 建物列表
export const queryBuildingList = `
query Building(
  $page: Int,
  $offset: Int,
  $keyword: String,
  $leaseType: Int,
  $leaseStatus: Int,
  $showRoom: Boolean,
  $showOwner: Boolean,
) {
  adminGetBuildingList(
    page: $page,
    offset: $offset,
    keyword: $keyword,
    leaseType: $leaseType,
    leaseStatus: $leaseStatus,
    showRoom: $showRoom,
    showOwner: $showOwner,
  ){
    totalCount
    itemList {
      oid
      name
      short
      addr
      zipCode
      leaseType
      leaseStatus
      roomNum
      room {
        oid
        name
        operationType
        expectedRentPrice
        expectedManagementFeeType
        expectedManagementFee
        managementFeeTax
        managementStartedAt
        managementEndAt
        isManagementEnd
        insuranceCompany
        fireInsuranceCompany
        fireInsuranceStartAt
        fireInsuranceEndAt
        isFireInsuranceEnd
        rent
        managementFee
        insuranceFee
        electricBasicFee
        waterCharge
        hotWaterCharge
        internetCharge
        bicycleCharge
        otherFee
        insuranceTotal
        renewalFee
        guarantyFee
        repairFee
        preSave
        maintenanceFee
        keyMoney
        deposit
        waterId
        electricId
        gasId
        waterBillingDate
        thisWaterAr
        thisWaterRi
        totalWaterAr
        totalWaterRi
        electricBillingDate
        thisElectricAr
        thisElectricRi
        totalElectricAr
        totalElectricRi
        gasBillingDate
        thisGasAr
        thisGasRi
        totalGasAr
        totalGasRi
        updateAt
      }
      owner {
        oid
        firstName
        firstNameEn
        lastName
        lastNameEn
        passportId
        mobile
        email
        wechat
        balance
      }
      waterBillingDate
      thisWaterAr
      thisWaterRi
      totalWaterAr
      totalWaterRi
      electricBillingDate
      thisElectricAr
      thisElectricRi
      totalElectricAr
      totalElectricRi
      gasBillingDate
      thisGasAr
      thisGasRi
      totalGasAr
      totalGasRi
      createAt
      updateAt
    }
  }
}
`;

// 建物详情
export const queryBuilding = `
query Building(
  $oid: String!,
) {
  adminGetBuilding(
    oid: $oid,
  ){
    oid
    name
    short
    addr
    zipCode
    leaseType
    leaseStatus
    roomNum
    room {
      oid
      name
      operationType
      expectedRentPrice
      expectedManagementFeeType
      expectedManagementFee
      managementFeeTax
      managementStartedAt
      managementEndAt
      isManagementEnd
      insuranceCompany
      fireInsuranceCompany
      fireInsuranceStartAt
      fireInsuranceEndAt
      isFireInsuranceEnd
      rent
      managementFee
      insuranceFee
      electricBasicFee
      waterCharge
      hotWaterCharge
      internetCharge
      bicycleCharge
      otherFee
      insuranceTotal
      renewalFee
      guarantyFee
      repairFee
      preSave
      maintenanceFee
      keyMoney
      deposit
      waterId
      electricId
      gasId
      waterBillingDate
      thisWaterAr
      thisWaterRi
      totalWaterAr
      totalWaterRi
      electricBillingDate
      thisElectricAr
      thisElectricRi
      totalElectricAr
      totalElectricRi
      gasBillingDate
      thisGasAr
      thisGasRi
      totalGasAr
      totalGasRi
      updateAt
    }
    owner {
      oid
      firstName
      firstNameEn
      lastName
      lastNameEn
      passportId
      mobile
      email
      wechat
      balance
    }
    waterBillingDate
    thisWaterAr
    thisWaterRi
    totalWaterAr
    totalWaterRi
    electricBillingDate
    thisElectricAr
    thisElectricRi
    totalElectricAr
    totalElectricRi
    gasBillingDate
    thisGasAr
    thisGasRi
    totalGasAr
    totalGasRi
    createAt
    updateAt
  }
}
`;

// 建物房东列表
export const queryBuildingOwnerList = `
query Owner(
  $page: Int,
  $offset: Int,
  $buildingId String,
) {
  adminGetBuildingOwnerList(
    page: $page,
    offset: $offset,
    buildingId: $buildingId,
  ){
    totalCount
    itemList {
      oid
      firstName
      firstNameEn
      lastName
      lastNameEn
      passportId
      mobile
      email
      wechat
      balance
      bankAccount
      bankAccountType
      bankAccountTypeNo
      bankBranchNumber
      bankId
      swiftcode
      bankName
      bankBranch
      createAt
      updateAt
    }
  }
}
`;

// 房间列表
export const queryRoomList = `
query Room(
  $page: Int,
  $offset: Int,
  $buildingId: String,
) {
  adminGetRoomList(
    page: $page,
    offset: $offset,
    buildingId: $buildingId,
  ){
    totalCount
    itemList {
      oid
      name
      operationType
      expectedRentPrice
      expectedManagementFeeType
      expectedManagementFee
      managementFeeTax
      managementStartedAt
      managementEndAt
      isManagementEnd
      insuranceCompany
      fireInsuranceCompany
      fireInsuranceStartAt
      fireInsuranceEndAt
      isFireInsuranceEnd
      rent
      managementFee
      insuranceFee
      electricBasicFee
      waterCharge
      hotWaterCharge
      internetCharge
      bicycleCharge
      otherFee
      insuranceTotal
      renewalFee
      guarantyFee
      repairFee
      preSave
      maintenanceFee
      keyMoney
      deposit
      waterId
      electricId
      gasId
      waterBillingDate
      thisWaterAr
      thisWaterRi
      totalWaterAr
      totalWaterRi
      electricBillingDate
      thisElectricAr
      thisElectricRi
      totalElectricAr
      totalElectricRi
      gasBillingDate
      thisGasAr
      thisGasRi
      totalGasAr
      totalGasRi
      createAt
      updateAt
    }
  }
}
`;

// 房间详情
export const queryRoom = `
query Room(
  $oid: String!,
) {
  adminGetRoom(
    oid: $oid,
  ){
    oid
    name
    operationType
    expectedRentPrice
    expectedManagementFeeType
    expectedManagementFee
    managementFeeTax
    managementStartedAt
    managementEndAt
    isManagementEnd
    insuranceCompany
    fireInsuranceCompany
    fireInsuranceStartAt
    fireInsuranceEndAt
    isFireInsuranceEnd
    rent
    managementFee
    insuranceFee
    electricBasicFee
    waterCharge
    hotWaterCharge
    internetCharge
    bicycleCharge
    otherFee
    insuranceTotal
    renewalFee
    guarantyFee
    repairFee
    preSave
    maintenanceFee
    keyMoney
    deposit
    waterId
    electricId
    gasId
    waterBillingDate
    thisWaterAr
    thisWaterRi
    totalWaterAr
    totalWaterRi
    electricBillingDate
    thisElectricAr
    thisElectricRi
    totalElectricAr
    totalElectricRi
    gasBillingDate
    thisGasAr
    thisGasRi
    totalGasAr
    totalGasRi
    createAt
    updateAt
  }
}
`;

// 金流列表
export const queryCashflowList = `
query Cashflow(
  $page: Int,
  $offset: Int,
  $amountMin: Int,
  $amountMax: Int,
  $direction: Int,
  $cashflowType: Int,
  $buildingId: String,
  $roomId: String,
  $counterpartType: Int,
  $counterpartOwnerId: String,
  $counterpartTenantId: String,
  $transactionTimeStartAt: Int,
  $transactionTimeEndAt: Int,
  $transactionType: Int,
  $transactionBankId: String,
  $bankId: String,
  $bankAccountId: String,
  $transactionNo: String,
  $status: Int,
  $statusList: [Int]
){
  adminGetCashflowList(
    page: $page,
    offset: $offset,
    amountMin: $amountMin,
    amountMax: $amountMax,
    direction: $direction,
    cashflowType: $cashflowType,
    buildingId: $buildingId,
    roomId: $roomId,
    counterpartType: $counterpartType,
    counterpartOwnerId: $counterpartOwnerId,
    counterpartTenantId: $counterpartTenantId,
    transactionTimeStartAt: $transactionTimeStartAt,
    transactionTimeEndAt: $transactionTimeEndAt,
    transactionType: $transactionType,
    transactionBankId: $transactionBankId,
    bankId: $bankId,
    bankAccountId: $bankAccountId,
    transactionNo: $transactionNo,
    status: $status,
    statusList: $statusList
  ){
    totalCount
    itemList {
      oid
      amount
      direction
      cashflowType
      counterpartType
      transactionTime
      transactionType
      transactionNo
      transactionOnlineAccount
      onlineAccount
      status
      memo
      operator {
        oid
        username
      }
      auditor {
        oid
        username
      }
      building {
        oid
        name
        short
        addr
        zipCode
        leaseType
        leaseStatus
        roomNum
      }
      room {
        oid
        name
        operationType
      }
      counterpartOwner {
        oid
        firstName
        firstNameEn
        lastName
        lastNameEn
        passportId
        mobile
        email
        wechat
        balance
      }
      counterpartTenant {
        oid
        firstName
        firstNameEn
        lastName
        lastNameEn
        passportId
        mobile
        email
        wechat
        balance
      }
      transactionBank {
        oid
        swiftcode
        bankName
        bankBranch
      }
      bank {
        oid
        swiftcode
        bankName
        bankBranch
      }
      bankAccount {
        oid
        bankAccount
        bankAccountType
        bankAccountTypeNo
        bankBranchNumber
      }
      createAt
      updateAt
    }
  }
}
`;

// 金流详情
export const queryCashflow = `
query Cashflow(
  $oid: String!,
) {
  adminGetBuilding(
    oid: $oid,
  ){
    oid
    amount
    direction
    cashflowType
    counterpartType
    transactionTime
    transactionType
    transactionNo
    transactionOnlineAccount
    onlineAccount
    status
    memo
    operator
    auditor
    building
    room
    counterpartOwner
    counterpartTenant
    transactionBank
    bank
    bankAccount
    createAt
    updateAt
  }
}
`;

/**
 * 交互类操作
 */
// 管理员登入
export const mutationPlatformUserLogin = `
mutation PlatformUser($username: String!, $password: String!) {
  platformUserLogin(username: $username, password: $password)
}
`;

// 修改当前用户自己的密码
export const mutationChangeMyPassword = `
mutation PlatformUser(
  $oldPassword: String!,
  $newPassword: String!
) {
  changeMyPassword(
    oldPassword: $oldPassword,
    newPassword: $newPassword
  )
}
`;

// 新增管理员角色
// PlatformRolePermissionInput 的定义来自后端，只要后端定义了，前端就不需要定义
export const mutationAddPlatformRole = `
mutation PlatformRole(
  $name: String!,
  $permissions: [PlatformRolePermissionInput!]!
) {
  adminAddPlatformRole(
    name: $name,
    permissions: $permissions
  )
}
`;

// 修改管理员角色
// PlatformRolePermissionInput 的定义来自后端，只要后端定义了，前端就不需要定义
export const mutationSetPlatformRole = `
mutation PlatformRole(
  $oid: Int!,
  $name: String!,
  $permissions: [PlatformRolePermissionInput!]!
) {
  adminSetPlatformRole(
    oid: $oid,
    name: $name,
    permissions: $permissions
  )
}
`;

// 新增管理员
export const mutationAddPlatformUser = `
mutation PlatformUser(
  $username: String!,
  $password: String!,
  $platformRoleId: Int!
) {
  adminAddPlatformUser(
    username: $username,
    password: $password,
    platformRoleId: $platformRoleId
  )
}
`;

// 修改管理员
export const mutationSetPlatformUser = `
mutation PlatformUser(
  $oid: Int!,
  $password: String,
  $platformRoleId: Int
) {
  adminSetPlatformUser(
    oid: $oid,
    password: $password,
    platformRoleId: $platformRoleId
  )
}
`;

// 删除管理员
export const mutationDelPlatformUser = `
mutation PlatformUser(
  $oid: Int!
) {
  adminDelPlatformUser(
    oid: $oid
  )
}
`;

// 删除管理员角色
export const mutationDelPlatformRole = `
mutation PlatformRole(
  $oid: Int!
) {
  adminDelPlatformRole(
    oid: $oid
  )
}
`;

// 新增银行
export const mutationAddBank = `
mutation Bank(
  $swiftcode: String!,
  $bankName: String!,
  $bankBranch: String!
) {
  adminAddBank(
    swiftcode: $swiftcode,
    bankName: $bankName,
    bankBranch: $bankBranch,
  )
}
`;

// 修改银行
export const mutationSetBank = `
mutation Bank(
  $oid: String!,
  $swiftcode: String!,
  $bankName: String!,
  $bankBranch: String!
) {
  adminSetBank(
    oid: $oid,
    swiftcode: $swiftcode,
    bankName: $bankName,
    bankBranch: $bankBranch,
  )
}
`;

// 删除银行
export const mutationDelBank = `
mutation Bank(
  $oid: String!,
) {
  adminDelBank(
    oid: $oid,
  )
}
`;

// 新增银行账号
export const mutationAddBankAccount = `
mutation BankAccount(
  $bankAccount: String!,
  $bankAccountType: Int!,
  $bankAccountTypeNo: String!,
  $swiftcode: String,
  $bankName: String,
  $bankBranch: String,
  $bankBranchNumber: String,
  $bankId: String
) {
  adminAddBankAccount(
    bankAccount: $bankAccount,
    bankAccountType: $bankAccountType,
    bankAccountTypeNo: $bankAccountTypeNo,
    swiftcode: $swiftcode,
    bankName: $bankName,
    bankBranch: $bankBranch,
    bankBranchNumber: $bankBranchNumber,
    bankId: $bankId,
  )
}
`;

// 修改银行账号
export const mutationSetBankAccount = `
mutation BankAccount(
  $oid: String!,
  $bankAccount: String,
  $bankAccountType: Int,
  $bankAccountTypeNo: String,
  $swiftcode: String,
  $bankName: String,
  $bankBranch: String,
  $bankBranchNumber: String,
  $bankId: String
) {
  adminSetBankAccount(
    oid: $oid,
    bankAccount: $bankAccount,
    bankAccountType: $bankAccountType,
    bankAccountTypeNo: $bankAccountTypeNo,
    swiftcode: $swiftcode,
    bankName: $bankName,
    bankBranch: $bankBranch,
    bankBranchNumber: $bankBranchNumber,
    bankId: $bankId,
  )
}
`;

// 删除银行账号
export const mutationDelBankAccount = `
mutation BankAccount(
  $oid: String!,
) {
  adminDelBankAccount(
    oid: $oid,
  )
}
`;

// 新增房东
export const mutationAddOwner = `
mutation Owner(
  $firstName: String!,
  $lastName: String!,
  $mobile: String!,
  $email: String!,
  $wechat: String!,
  $firstNameEn: String,
  $lastNameEn: String,
  $bankId: String,
  $swiftcode: String,
  $bankName: String,
  $bankBranch: String,
  $bankBranchNumber: String,
  $bankAccount: String,
  $bankAccountType: Int,
  $bankAccountTypeNo: String,
  $balance: Int
) {
  adminAddOwner(
    firstName: $firstName,
    lastName: $lastName,
    mobile: $mobile,
    email: $email,
    wechat: $wechat,
    firstNameEn: $firstNameEn,
    lastNameEn: $lastNameEn,
    bankId: $bankId,
    swiftcode: $swiftcode,
    bankName: $bankName,
    bankBranch: $bankBranch,
    bankBranchNumber: $bankBranchNumber,
    bankAccount: $bankAccount,
    bankAccountType: $bankAccountType,
    bankAccountTypeNo: $bankAccountTypeNo,
    balance: $balance
  )
}
`;

// 删除房间
export const mutationDelOwner = `
mutation Owner(
  $oid: String!,
) {
  adminDelOwner(
    oid: $oid,
  )
}
`;

// 新增入住人
export const mutationAddTenant = `
mutation Tenant(
  $firstName: String!,
  $lastName: String!,
  $passportId: String!,
  $mobile: String!,
  $email: String!,
  $wechat: String!,
  $firstNameEn: String,
  $lastNameEn: String,
  $bankId: String,
  $swiftcode: String,
  $bankName: String,
  $bankBranch: String,
  $bankBranchNumber: String,
  $bankAccount: String,
  $bankAccountType: Int,
  $bankAccountTypeNo: String,
  $balance: Int,
) {
  adminAddTenant(
    firstName: $firstName,
    lastName: $lastName,
    passportId: $passportId,
    mobile: $mobile,
    email: $email,
    wechat: $wechat,
    firstNameEn: $firstNameEn,
    lastNameEn: $lastNameEn,
    bankId: $bankId,
    swiftcode: $swiftcode,
    bankName: $bankName,
    bankBranch: $bankBranch,
    bankBranchNumber: $bankBranchNumber,
    bankAccount: $bankAccount,
    bankAccountType: $bankAccountType,
    bankAccountTypeNo: $bankAccountTypeNo,
    balance: $balance,
  )
}
`;

// 修改入住人
export const mutationSetTenant = `
mutation Tenant(
  $oid: String!,
  $firstName: String!,
  $lastName: String!,
  $passportId: String!,
  $mobile: String!,
  $email: String!,
  $wechat: String!,
  $firstNameEn: String,
  $lastNameEn: String,
  $bankId: String,
  $swiftcode: String,
  $bankName: String,
  $bankBranch: String,
  $bankBranchNumber: String,
  $bankAccount: String,
  $bankAccountType: Int,
  $bankAccountTypeNo: String,
  $balance: Int,
) {
  adminSetTenant(
    oid: $oid,
    firstName: $firstName,
    lastName: $lastName,
    passportId: $passportId,
    mobile: $mobile,
    email: $email,
    wechat: $wechat,
    firstNameEn: $firstNameEn,
    lastNameEn: $lastNameEn,
    bankId: $bankId,
    swiftcode: $swiftcode,
    bankName: $bankName,
    bankBranch: $bankBranch,
    bankBranchNumber: $bankBranchNumber,
    bankAccount: $bankAccount,
    bankAccountType: $bankAccountType,
    bankAccountTypeNo: $bankAccountTypeNo,
    balance: $balance,
  )
}
`;

// 删除入住人
export const mutationDelTenant = `
mutation Tenant(
  $oid: String!,
) {
  adminDelTenant(
    oid: $oid,
  )
}
`;

// 新增建物
export const mutationAddBuilding = `
mutation Building(
  $name: String!,
  $addr: String!,
  $short: String,
  $zipCode: String,
  $leaseType: Int,
  $leaseStatus: Int,
  $waterBillingDate: String,
  $electricBillingDate: String,
  $gasBillingDate: String
) {
  adminAddBuilding(
    name: $name,
    addr: $addr,
    short: $short,
    zipCode: $zipCode,
    leaseType: $leaseType,
    leaseStatus: $leaseStatus,
    waterBillingDate: $waterBillingDate,
    electricBillingDate: $electricBillingDate,
    gasBillingDate: $gasBillingDate
  )
}
`;

// 修改建物
export const mutationSetBuilding = `
mutation Building(
  $oid: String!,
  $name: String!,
  $addr: String!,
  $short: String,
  $zipCode: String,
  $leaseType: Int,
  $leaseStatus: Int,
  $waterBillingDate: String,
  $electricBillingDate: String,
  $gasBillingDate: String
) {
  adminSetBuilding(
    oid: $oid,
    name: $name,
    addr: $addr,
    short: $short,
    zipCode: $zipCode,
    leaseType: $leaseType,
    leaseStatus: $leaseStatus,
    waterBillingDate: $waterBillingDate,
    electricBillingDate: $electricBillingDate,
    gasBillingDate: $gasBillingDate
  )
}
`;

// 删除建物
export const mutationDelBuilding = `
mutation Building(
  $oid: String!,
) {
  adminDelBuilding(
    oid: $oid,
  )
}
`;

// 新增房间
export const mutationAddRoom = `
mutation Room(
  $name: String!,
  $buildingId: String!,
  $waterId: String!,
  $electricId: String!,
  $gasId: String!,
  $waterBillingDate: String!,
  $electricBillingDate: String!,
  $gasBillingDate: String!,
  $operationType: Int!,
  $expectedRentPrice: Int!,
  $expectedManagementFeeType: Int!,
  $expectedManagementFee: Float!,
  $managementFeeTax: Int!,
  $managementStartedAt: String!,
  $managementEndAt: String!,
  $insuranceCompany: String!,
  $fireInsuranceCompany: String!,
  $fireInsuranceStartAt: String!,
  $fireInsuranceEndAt: String!,
  $rent: Int!,
  $managementFee: Int!,
  $insuranceFee: Int!,
  $electricBasicFee: Int!,
  $waterCharge: Int!,
  $hotWaterCharge: Int!,
  $internetCharge: Int!,
  $bicycleCharge: Int!,
  $otherFee: Int!,
  $renewalFee: Int,
  $guarantyFee: Int,
  $repairFee: Int,
  $preSave: Int,
  $maintenanceFee: Int,
  $keyMoney: Int,
  $deposit: Int
) {
  adminAddRoom(
    name: $name,
    buildingId: $buildingId,
    waterId: $waterId,
    electricId: $electricId,
    gasId: $gasId,
    waterBillingDate: $waterBillingDate,
    electricBillingDate: $electricBillingDate,
    gasBillingDate: $gasBillingDate,
    operationType: $operationType,
    expectedRentPrice: $expectedRentPrice,
    expectedManagementFeeType: $expectedManagementFeeType,
    expectedManagementFee: $expectedManagementFee,
    managementFeeTax: $managementFeeTax,
    managementStartedAt: $managementStartedAt,
    managementEndAt: $managementEndAt,
    insuranceCompany: $insuranceCompany,
    fireInsuranceCompany: $fireInsuranceCompany,
    fireInsuranceStartAt: $fireInsuranceStartAt,
    fireInsuranceEndAt: $fireInsuranceEndAt,
    rent: $rent,
    managementFee: $managementFee,
    insuranceFee: $insuranceFee,
    electricBasicFee: $electricBasicFee,
    waterCharge: $waterCharge,
    hotWaterCharge: $hotWaterCharge,
    internetCharge: $internetCharge,
    bicycleCharge: $bicycleCharge,
    otherFee: $otherFee,
    renewalFee: $renewalFee,
    guarantyFee: $guarantyFee,
    repairFee: $repairFee,
    preSave: $preSave,
    maintenanceFee: $maintenanceFee,
    keyMoney: $keyMoney,
    deposit: $deposit
  )
}
`;

// 修改房间
export const mutationSetRoom = `
mutation Room(
  $oid: String!,
  $name: String!,

  $waterId: String!,
  $electricId: String!,
  $gasId: String!,
  $waterBillingDate: String!,
  $electricBillingDate: String!,
  $gasBillingDate: String!,
  $operationType: Int!,
  $expectedRentPrice: Int!,
  $expectedManagementFeeType: Int!,
  $expectedManagementFee: Float!,
  $managementFeeTax: Int!,
  $managementStartedAt: String!,
  $managementEndAt: String!,
  $insuranceCompany: String!,
  $fireInsuranceCompany: String!,
  $fireInsuranceStartAt: String!,
  $fireInsuranceEndAt: String!,
  $rent: Int!,
  $managementFee: Int!,
  $insuranceFee: Int!,
  $electricBasicFee: Int!,
  $waterCharge: Int!,
  $hotWaterCharge: Int!,
  $internetCharge: Int!,
  $bicycleCharge: Int!,
  $otherFee: Int!,
  $renewalFee: Int,
  $guarantyFee: Int,
  $repairFee: Int,
  $preSave: Int,
  $maintenanceFee: Int,
  $keyMoney: Int,
  $deposit: Int
) {
  adminSetRoom(
    oid: $oid,
    name: $name,

    waterId: $waterId,
    electricId: $electricId,
    gasId: $gasId,
    waterBillingDate: $waterBillingDate,
    electricBillingDate: $electricBillingDate,
    gasBillingDate: $gasBillingDate,
    operationType: $operationType,
    expectedRentPrice: $expectedRentPrice,
    expectedManagementFeeType: $expectedManagementFeeType,
    expectedManagementFee: $expectedManagementFee,
    managementFeeTax: $managementFeeTax,
    managementStartedAt: $managementStartedAt,
    managementEndAt: $managementEndAt,
    insuranceCompany: $insuranceCompany,
    fireInsuranceCompany: $fireInsuranceCompany,
    fireInsuranceStartAt: $fireInsuranceStartAt,
    fireInsuranceEndAt: $fireInsuranceEndAt,
    rent: $rent,
    managementFee: $managementFee,
    insuranceFee: $insuranceFee,
    electricBasicFee: $electricBasicFee,
    waterCharge: $waterCharge,
    hotWaterCharge: $hotWaterCharge,
    internetCharge: $internetCharge,
    bicycleCharge: $bicycleCharge,
    otherFee: $otherFee,
    renewalFee: $renewalFee,
    guarantyFee: $guarantyFee,
    repairFee: $repairFee,
    preSave: $preSave,
    maintenanceFee: $maintenanceFee,
    keyMoney: $keyMoney,
    deposit: $deposit
  )
}
`;

// 删除房间
export const mutationDelRoom = `
mutation Room(
  $oid: String!,
) {
  adminDelRoom(
    oid: $oid,
  )
}
`;

// 新增金流
export const mutationAddCashflow = `
mutation Cashflow(
  $amount: Int!,
  $transactionTime: String!,
  $transactionNo: String!,
  $transactionType: Int,
  $transactionBankId: String,
  $transactionOnlineAccount: String,
  $direction: Int!,
  $cashflowType: Int,
  $bankAccountId: String,
  $onlineAccount: String,
  $buildingId: String,
  $roomId: String,
  $counterpartType: Int,
  $counterpartOwnerId: String,
  $counterpartTenantId: String,
  $memo: String
) {
  adminAddRoom(
    amount: $amount,
    transactionTime: $transactionTime,
    transactionNo: $transactionNo,
    transactionType: $transactionType,
    transactionBankId: $transactionBankId,
    transactionOnlineAccount: $transactionOnlineAccount,
    direction: $direction,
    cashflowType: $cashflowType,
    bankAccountId: $bankAccountId,
    onlineAccount: $onlineAccount,
    buildingId: $buildingId,
    roomId: $roomId,
    counterpartType: $counterpartType,
    counterpartOwnerId: $counterpartOwnerId,
    counterpartTenantId: $counterpartTenantId,
    memo: $memo
  )
}
`;

// 修改金流
export const mutationSetCashflow = `
mutation Cashflow(
  $oid: String!,
  $amount: Int!,
  $transactionTime: String!,
  $transactionNo: String!,
  $transactionType: Int,
  $transactionBankId: String,
  $transactionOnlineAccount: String,
  $direction: Int!,
  $cashflowType: Int,
  $bankAccountId: String,
  $onlineAccount: String,
  $buildingId: String,
  $roomId: String,
  $counterpartType: Int,
  $counterpartOwnerId: String,
  $counterpartTenantId: String,
  $memo: String
) {
  adminSetCashflow(
    oid: $oid,
    amount: $amount,
    transactionTime: $transactionTime,
    transactionNo: $transactionNo,
    transactionType: $transactionType,
    transactionBankId: $transactionBankId,
    transactionOnlineAccount: $transactionOnlineAccount,
    direction: $direction,
    cashflowType: $cashflowType,
    bankAccountId: $bankAccountId,
    onlineAccount: $onlineAccount,
    buildingId: $buildingId,
    roomId: $roomId,
    counterpartType: $counterpartType,
    counterpartOwnerId: $counterpartOwnerId,
    counterpartTenantId: $counterpartTenantId,
    memo: $memo
  )
}
`;

// 删除金流
export const mutationDelCashflow = `
mutation Cashflow(
  $oid: String!,
) {
  adminDelCashflow(
    oid: $oid,
  )
}
`;

/**
 * GQL异步获取函数
 * @param gql  gql查询语句
 * @param params 参数
 */
export const dataFetcher = async (gql: string, params?: any) => {
  const gg = {
    query: gql,
    variables: params,
  };
  if (isTauri) {
    // ? 记得修复一下
    // let jwt: string | null = '';
    // if (typeof window !== 'undefined') {
    //   // Perform localStorage action
    //   jwt = localStorage.getItem('jwt');
    // }
    // console.log('我是jwt', jwt);
    // const client = await getClient();
    // const response = await client.post(GQL_API, Body.json(gg), {
    //   headers: { Authorization: 'Bearer: ' + jwt },
    // });
    // console.log('我在拉数据', response);
    // if (response.status === 200) {
    //   // @ts-ignore
    //   if (!response.data.data && !response.data.errors) {
    //     return Promise.resolve(response.data);
    //   }
    //   // @ts-ignore
    //   if (response.data.errors) {
    //     // @ts-ignore
    //     console.log('我是错误', response.data.errors[0].message);
    //     // 调整为如果有需要特殊处理的code附加在||之后
    //     // @ts-ignore
    //     let message = response.data.errors[0].message;
    //     if (message.includes('||')) {
    //       const msgBox = message.split('||');
    //       if (msgBox[1] === '401') {
    //         notification.error({
    //           message: 'Error',
    //           description: '请先登录',
    //         });
    //         localStorage.removeItem('jwt');
    //         localStorage.setItem('userStatus', 'logout');
    //         document.location.href = '/login';
    //         return;
    //       } else {
    //         message = msgBox[0];
    //       }
    //     }
    //     // 任何错误直接返回
    //     console.log('error==', message);
    //     notification.error({
    //       message: 'Error',
    //       description: message,
    //     });
    //     console.log('err', message);
    //     return Promise.reject(message);
    //   }
    //   // 没有错误 数据返回
    //   // @ts-ignore
    //   return Promise.resolve(response.data.data);
    // } else {
    //   return Promise.reject(response);
    // }
  } else {
    return request.post(GQL_API, JSON.stringify(gg));
  }
};

// 给swr2.0适配用的函数
export const swrDataFetcher = async (item: any[]) => {
  if (!item[0]) {
    // 请在这里返回一个错误信息
    notification.error({
      message: 'Error',
      description: '错误的gql格式',
    });
    return;
  }
  const gql = item[0];
  const params = item[1] ? item[1] : null;
  return dataFetcher(gql, params);
};

/**
 * 下载导出文件
 * @param params
 */
export const download = async (params?: any) => {
  return request({
    method: 'get',
    url: '/api/admin/download',
    responseType: 'blob',
    params: {
      ...params,
    },
  });
};

/**
 * 上传图片
 * @param params 请求参数
 */
export const upload_image = async (params?: any) => request.post('/api/admin/upload.json', params);
