import axios from 'axios';

import { notification } from 'antd';

const instance: any = axios.create({});
//请求拦截
instance.interceptors.request.use(
  (config:any) => {
    const jwt = localStorage.getItem("jwt");

    config.headers = {
      ...config.headers,
      Authorization: "Bearer: " + jwt,
    };

    return config;
  },
  (err:any) => {
    console.log("err", err);
    return Promise.reject(err);
  }
);

// 接收拦截
instance.interceptors.response.use(
  (res: any) => {


    if (res.status === 200) {

      if (!res.data.data && !res.data.errors) {
        return Promise.resolve(res);
      }

      if (res.data.errors) {
        console.log('我是错误', res.data.errors[0].message);
        // 调整为如果有需要特殊处理的code附加在||之后
        let message: string = res.data.errors[0].message;
        if (message.includes('||')) {
          const msgBox: string[] = message.split('||');
          if (msgBox[1] === '401') {
            notification.error({
              message: 'Error',
              description: '请先登录',
            });
            //localStorage.removeItem('jwt');
            // localStorage.setItem('userStatus', 'logout');
            document.location.href = '/user/login';
            return;
          } else {
            message = msgBox[0];
          }
        }
        notification.error({
          message: 'Error',
          description: message,
        });
        console.log('err', message);
        return Promise.reject(message);
      }
      // 没有错误 数据返回
      return Promise.resolve(res.data.data);
    } else {
      return Promise.reject(res);
    }
  },
  (err: any) => {
    notification.error({
      message: 'Error',
      description: '接口或网络错误',
    });
    console.log('err', err);
    return Promise.reject(err);
  },
);

export default instance;
